<template>
  <div class="input-group">
    <textarea id="YourTextArea" placeholder="inserisci una breve descrizione" @input="$emit('setDescription', $event.target.value)"></textarea>
  </div>
</template>

<script>

export default {
  name: 'TextArea'
}
</script>
