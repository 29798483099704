<template>
  <div class="inputFields">
    <RadioGroup v-if="type === 'radio'" :options="options" :request="false" @setOption="setOption" />
    <TextArea v-if="type === 'textarea'" @setDescription="setDescription" />
    <DatePicker v-if="type === 'datetime'" :modelValue="date" @update:modelValue="setCalendar" format="dd/MM/yyyy HH:mm" locale="it" placeholder="seleziona giorno e ora" cancelText="cancella" selectText="conferma" dark />
  </div>
</template>

<script>

import RadioGroup from './RadioGroup.vue'
import TextArea from './TextArea.vue'
import DatePicker from 'vue3-date-time-picker'
// eslint-disable-next-line no-unused-vars
import { it } from 'date-fns/locale'
import 'vue3-date-time-picker/dist/main.css'

export default {
  name: 'InputFields',
  props: {
    type: String,
    options: Array,
    modelValue: String
  },
  methods: {
    setOption (value) {
      this.$emit('update:option', value)
    },
    setCalendar (value) {
      console.log(value)
      this.$emit('update:calendar', value)
    },
    setDescription (value) {
      console.log('ok')
      this.$emit('update:description', value)
    }
  },
  data () {
    return {
      date: '',
      description: ''
    }
  },
  components: { RadioGroup, TextArea, DatePicker }
}
</script>
