<template>
  <div class="input-group" v-if="request">
    <RadioSingle v-for="(option, index) in options" :request="true" :option="option" :key="index" @getRequest="getRequest" />
  </div>
  <div class="input-group" v-else>
    <RadioSingle v-for="(option, index) in options" :request="false" :option="option" :key="index" @getOption="getOption" />
  </div>

</template>

<script>
import RadioSingle from './RadioSingle.vue'

export default {
  name: 'RadioGroup',
  components: {
    RadioSingle
  },
  props: {
    options: {
      required: true,
      type: Object
    },
    request: {
      required: true,
      type: Boolean
    },
    modelValue: {
      required: false,
      type: String
    }
  },
  methods: {
    getRequest (value) {
      this.$emit('update:modelValue', value)
    },
    getOption (value) {
      this.$emit('setOption', value)
    }
  }
}
</script>

<style scoped>
/* The container */
.input-group {
  display: flex;
  justify-content: space-evenly;
}
@media only screen and (max-width: 600px) {
  .input-group {
    display: block;
  }
}
</style>
