<template>
  <div id="loading">
    <img src="assets/svg/ripple.svg" alt="ripple" />
  </div>
</template>

<script>
export default {
  name: 'RippleLoading'
}
</script>

<style scoped>
  #loading {
      position: fixed;
      z-index: 100;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.48);
      opacity: 0;
      visibility: hidden;
    }
    #loading.active {
      opacity: 1;
      visibility: visible;
    }
    #loading img {
      width: 50vw;
      height: 50vw;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
</style>
