<template>
  <div id="soundWave">
    <svg
      version="1.1"
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="96px"
      height="96px"
      viewBox="0 0 96 96"
      style="enable-background: new 0 0 96 96"
      xml:space="preserve"
    >
      <path
        id="Line_1"
        class="st0"
        d="M91.3,66.9c-2.7,0-4.7-2.1-4.7-4.8V33.8c0-2.7,2.1-4.8,4.7-4.8c2.7,0,4.7,2.1,4.7,4.8v28.3C96,64.8,93.9,66.9,91.3,66.9z"
      />
      <path
        id="Line_2"
        class="st0"
        d="M69.6,81.8c-2.7,0-4.7-2.1-4.7-4.8V18.8c0-2.7,2.1-4.8,4.7-4.8s4.7,2.1,4.7,4.8V77C74.4,79.7,72.3,81.8,69.6,81.8z"
      />
      <path
        id="Line_3"
        class="st0"
        d="M48,95.8c-2.7,0-4.7-2.1-4.7-4.8V4.8C43.3,2.1,45.3,0,48,0s4.7,2.1,4.7,4.8V91C52.7,93.7,50.7,95.8,48,95.8z"
      />
      <path
        id="Line_4"
        class="st0"
        d="M26.4,81.8c-2.7,0-4.7-2.1-4.7-4.8V18.8c0-2.7,2.1-4.8,4.7-4.8s4.7,2.1,4.7,4.8V77C31.1,79.7,29.1,81.8,26.4,81.8z"
      />
      <path
        id="Line_5"
        class="st0"
        d="M4.7,67.8C2.1,67.8,0,65.8,0,63V32.8C0,30.1,2.1,28,4.7,28s4.7,2.1,4.7,4.8V63C9.5,65.8,7.4,67.8,4.7,67.8z"
      />
    </svg>
  </div>
</template>

<style scoped>
svg {
  height: 100%;
  width: 100%;
}
.st0 {
  fill: white;
  animation: pulse 0.4s infinite;
}
.play .st0 {
  fill: white;
  animation: pulse 0.4s infinite;
}
.stop .st0 {
  animation: pulse 0.4s 2;
}
#Line_1 {
  animation-delay: 0.15s;
}
#Line_2 {
  animation-delay: 0.3s;
}
#Line_3 {
  animation-delay: 0.45s;
}
#Line_4 {
  animation-delay: 0.6s;
}
#Line_5 {
  animation-delay: 0.75s;
}
@keyframes pulse {
  0% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }
  50% {
    transform: scaleY(0.7);
    transform-origin: 50% 50%;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }
}
</style>
