<template>
  <div id="assistant">
    <div class="wrapper">
      <SoundWave v-bind:class="[this.isActive && this.audioPlaying ? 'play' : 'stop']" />
      <div class="message" v-html="currentMessage">
      </div>
      <div class="interaction">
        <div v-if="currentStep === numberOfSteps">
          <input type="email" v-model="userEmail" placeholder="inserisci la tua email" >
          <div class="button" :class="submitButton?' active':''" v-on:click="submitData()">INVIA</div>
        </div>
        <div v-else>
          <input type="text" v-if="currentStep === 1" :value="userName" @input="e => userName = e.target.value" placeholder="inserisci il tuo nome">
          <RadioGroup v-if="currentStep === 2" v-model="userRequest" :request="true" :options="questionsList"  />
          <InputFields
            v-if="currentStep > 2 && currentStep < numberOfSteps"
            v-model:calendar="userInput.calendar"
            v-model:description="userInput.description"
            v-model:option="userInput.option"
            v-bind="inputData"
          />
          <div class="button" :class="stepButton?' active':''" v-on:click="goToNextStep()">PROCEDI</div>
        </div>
      </div>
    </div>
    <audio id="audio" v-on:ended="audioEnded">
      <source v-bind:src="audioURL" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  </div>
</template>

<script>

import assistant from '../data/assistant.json'
import axios from 'axios'
import SoundWave from './SoundWave.vue'
import RadioGroup from './RadioGroup.vue'
import InputFields from './InputFields.vue'

export default {
  name: 'VocalAssistant',
  components: { SoundWave, RadioGroup, InputFields },
  props: { isActive: Boolean },
  watch: {
    isActive (val) {
      if (val) {
        this.currentStep = 1
        this.showCurrentStep()
      } else {
        // document.getElementById('audio').stop()
        this.currentStep = 0
        this.userName = ''
        this.userEmail = ''
        this.userRequest = ''
        this.userInput = { option: '', calendar: '', description: '' }
      }
    },
    userName (val) {
      console.log(val)
      if (val.length > 2) {
        this.stepButton = true
      } else {
        this.stepButton = false
      }
    },
    userRequest (val) {
      if (val !== '') {
        this.stepButton = true
      } else {
        this.stepButton = false
      }
    },
    userInput: {
      deep: true,
      immediate: true,
      handler (val) {
        switch (this.inputData.type) {
          case 'radio':
            if (this.userInput.option !== '') {
              this.stepButton = true
            } else {
              this.stepButton = false
            }
            break
          case 'textarea':
            if (this.userInput.description.length > 2) {
              this.stepButton = true
            } else {
              this.stepButton = false
            }
            break
          case 'datetime':
            if (this.userInput.calendar !== '') {
              this.stepButton = true
            } else {
              this.stepButton = false
            }
            break
          default:
        }
      }
    },
    userEmail (val) {
      if (val !== '' && this.regExp.test(this.userEmail)) {
        this.submitButton = true
      } else {
        this.submitButton = false
      }
    }
  },
  methods: {
    goToNextStep: function () {
      if (this.stepButton) {
        this.currentStep++
        this.showCurrentStep()
        this.stepButton = false
      }
    },
    showCurrentStep: function () {
      let filteredChoice = ''
      const choiceStep = this.currentStep - 3
      switch (this.currentStep) {
        case 1:
          this.listenAudio('assets/mp3/hello.mp3')
          this.currentMessage = 'Ciao, mi chiamo Alessandro Avallone e sono uno sviluppatore creativo: qual è il tuo nome?'
          break
        case 2:
          this.sendName()
          this.currentMessage = `Ciao ${this.userName}, piacere di conoscerti: chiedimi qualcosa`
          break
        default:
          filteredChoice = this.filteredChoice()
          this.numberOfSteps = filteredChoice.length + 3
          if (choiceStep < filteredChoice.length) {
            this.listenAudio(`assets/mp3/${this.userRequest}-${choiceStep + 1}.mp3`)
            this.currentMessage = filteredChoice[choiceStep].label
            this.inputData = { type: filteredChoice[choiceStep].type, options: filteredChoice[choiceStep].options }
          } else {
            this.listenAudio('assets/mp3/email.mp3')
            this.currentMessage = 'ottimo! qual è la tua casella di posta preferita?'
          }
      }
    },
    sendName: async function (event) {
      this.$emit('toggleRippleLoading', true)
      await axios
        .post(this.pollyServerURL, { user_name: this.userName })
        .then(response => this.listenAudio(response.data))
    },
    submitData: async function (event) {
      this.$emit('toggleRippleLoading', true)
      await axios
        .post(this.mailerServerURL, { user_name: this.userName, user_email: this.userEmail, user_request: this.userRequest, user_input: this.userInput })
        .then(response => this.$emit('showThanksMessage', this.userName))
    },
    audioEnded: function (event) {
      this.audioPlaying = false
    },
    listenAudio: function (source) {
      this.audioURL = source
      this.audioPlaying = true
      this.$emit('toggleRippleLoading', false)
      document.getElementById('audio').load()
      document.getElementById('audio').play()
    },
    filteredChoice: function () {
      let tempChoices = this.questionsList
      tempChoices = tempChoices.filter((item) => {
        return (item.request === this.userRequest)
      })
      return tempChoices[0].answers
    }
  },
  data () {
    return {
      submitButton: false,
      stepButton: false,
      numberOfSteps: 3,
      currentStep: 1,
      currentMessage: 'Ciao, mi chiamo Alessandro Avallone e sono uno sviluppatore creativo: qual è il tuo nome?',
      audioURL: '',
      audioPlayer: document.getElementById('audio'),
      audioPlaying: false,
      questionsList: assistant,
      userName: '',
      userEmail: '',
      regExp: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      userRequest: '',
      userInput: { option: '', calendar: '', description: '' },
      inputData: { type: '', options: [] },
      choiceID: '',
      pollyServerURL: 'https://node.alessandroavallone.it/2',
      mailerServerURL: 'https://node.alessandroavallone.it/3'
    }
  },
  computed: {

  }
}
</script>

<style scoped>
#assistant {
  width: calc(100vw - 24px);
  height: calc(100vh - 24px);
  padding: 48px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.048);
  border-radius: 24px;
  position: fixed;
  z-index: 8;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.24s ease-in;
}
#assistant.notActive {
  opacity: 0;
  visibility: hidden;
}
#assistant.active {
  opacity: 1;
  visibility: visible;
}
#assistant .message {
  width: 60%;
  font-size: 36px;
  line-height: 1;
  text-align: center;
  margin: 48px auto;
  font-style: italic;
}
#assistant #soundWave {
    width: 64px;
    height: 64px;
    border: 1px solid rgba(255, 255, 255, 0.48);
    border-radius: 64px;
    padding: 16px;
    box-sizing: border-box;
    margin: 48px auto;
}
#assistant .interaction {
  margin: 48px auto;
}
#assistant .interaction .button {
    display: block;
    width: 148px;
    padding: 12px;
    margin: 48px auto;
    border-radius: 24px;
    border: 2px solid var(--color-secondary);
    box-sizing: border-box;
    font-family: 'Barlow Condensed', sans-serif;
    text-align: center;
    color: white;
    font-size: 18px;
    opacity: 0.48;
    transition: all 0.24s linear;
    cursor: default;
}
#assistant .interaction .button.active {
    opacity: 1;
    box-shadow: 0 0 24px #fff, inset 0 0 24px #fff;
    cursor: pointer;
}
@media only screen and (max-width: 600px) {
    #assistant {
        width: calc(100vw - 12px);
        height: calc(100vh - 12px);
        padding: 12px;
        overflow: scroll;
    }
    #assistant .message {
      width: 100%;
      font-size: 24px;
      margin: 24px auto;
    }
    #assistant #soundWave {
      width: 48px;
      height: 48px;
      padding: 12px;
      margin: 24px auto;
    }
}
</style>
