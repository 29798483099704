<template>

  <!-- logo -->
  <img v-on:click='toggleVocalAssistant(false)' alt="Alessandro Avallone Logo" src="assets/svg/logo.svg" id="logo" />

  <!-- bubble -->
  <img v-on:click='toggleVocalAssistant(true)' v-bind:class="[vocalAssistantActive ? 'notActive' : 'active']" alt="Speak with me" src="assets/svg/bubble.svg" id="bubble" />

  <!-- video -->
  <video v-bind:class="[vocalAssistantActive ? 'notActive' : 'active']" playsinline autoplay muted loop>
    <source
      src="assets/mp4/alessandro-avallone-480p.mp4"
      type="video/mp4"
    />
    Your browser does not support the video tag.
  </video>

  <!-- ropple loading -->
  <RippleLoading
    v-bind:class="[rippleLoadingActive ? 'active' : 'notActive']"
  />

  <!-- hello world -->
  <HelloWorld
    v-bind:class="[vocalAssistantActive ? 'notActive' : 'active']"
    :thanksMessage="submittedForm"
    :userName="userName"
  />

  <!-- vocal assistant -->
  <VocalAssistant
    v-bind:class="[vocalAssistantActive ? 'active' : 'notActive']"
    v-bind:isActive="vocalAssistantActive"
    v-bind:formIsSubmitted="subittedForm"
    @toggleRippleLoading="toggleRippleLoading"
    @showThanksMessage="showThanksMessage"
  />

</template>

<script>

import HelloWorld from './components/HelloWorld.vue'
import VocalAssistant from './components/VocalAssistant.vue'
import RippleLoading from './components/RippleLoading.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    VocalAssistant,
    RippleLoading
  },
  methods: {
    toggleVocalAssistant: function (isActive) {
      this.vocalAssistantActive = isActive
    },
    toggleRippleLoading: function (isActive) {
      this.rippleLoadingActive = isActive
    },
    showThanksMessage (userName) {
      this.rippleLoadingActive = false
      this.vocalAssistantActive = false
      this.submittedForm = true
      this.userName = userName
    }
  },
  data () {
    return {
      vocalAssistantActive: false,
      rippleLoadingActive: false,
      submittedForm: false,
      userName: ''
    }
  },
  mounted () {

  }
}

</script>
