<template>
  <label v-if="request" :for="option.id" class="container">{{ option.label }}
    <input
      type="radio"
      :value="option.request"
      :id="option.id"
      @change="$emit('getRequest', $event.target.value)"
      name="radio-input"
    />
    <span class="checkmark"></span>
  </label>
  <label v-else :for="option" class="container">{{ option }}
    <input
      type="radio"
      :value="option"
      :id="option"
      @change="$emit('getOption', $event.target.value)"
      name="radio-input"
    />
  <span class="checkmark"></span>
</label>
</template>

<script>
export default {
  props: ['option', 'request']
}
</script>

<style scoped>
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 36px;
  margin: 24px;
  cursor: pointer;
  font-size: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 1px solid white;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: transparent
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: transparent
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
width: 50%;
height: 50%;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
border-radius: 50%;
background: white;
}

@media only screen and (max-width: 600px) {
  .container {
    font-size: 18px;
  }
}
</style>
