<template>
  <div id="hello">
    <div class="marquee">
      <div class="track">
        <div class="content">
          <div v-if="!thanksMessage">
            Ciao, mi chiamo <span>Alessandro</span> <span>Avallone</span> e sono uno sviluppatore creativo. Ciao, mi chiamo <span>Alessandro</span> <span>Avallone</span> e sono uno sviluppatore creativo.
          </div>
          <div v-else>
            <span>{{ userName }}</span> <span>grazie</span> per aver parlato con me, risponderò alla tua richiesta nel più breve tempo possibile. <span>{{ userName }}</span> <span>grazie</span> per aver parlato con me, risponderò alla tua richiesta nel più breve tempo possibile.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    thanksMessage: {
      required: true,
      type: Boolean
    },
    userName: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped>
  #hello {
      position: fixed;
      left: 0;
      bottom: 1vh;
      width: 100%;
      height: 4vw;
      z-index: 9;
      transition: all 0.24s ease-in;
    }
  #hello.active {
      opacity: 1;
      visibility: visible;
  }
  #hello.notActive {
      opacity: 0;
      visibility: hidden;
  }
  .marquee {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100%;
    overflow: hidden;
    font-family: "Space Mono", monospace;
    font-size: 4vw;
    line-height: 2.8vw;
  }
  .track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 8s linear infinite;
  }
  .marquee span:nth-of-type(odd) {
    color: #9425e8;
  }
  .marquee span:nth-of-type(even) {
    color: #45d1c0;
  }
  @keyframes marquee {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
  }
  @media only screen and (max-width: 600px) {
    #hello {
      bottom: 0;
      height: 48px;
    }
    .marquee {
      font-size: 32px;
      line-height: 1;
    }
  }

</style>
